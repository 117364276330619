import { createStore } from 'vuex'
import { ws } from "./modules/ws.module";
import { settings } from "./modules/settings.module";
import { layout } from "./modules/layout.module";
import { api } from './modules/api.module';
import { cart } from "./modules/cart.module";

const store = createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    layout,
    ws,
    settings,
    api,
    cart
  }
})

window.store = store;

export default store