
export const cart = {
    namespaced: true,
    state: {
        items: []
    },
    getters: {
        getItems: (state) => { return state.items; }
    },
    mutations: {
        SET_ITEMS: function(state, items) {
            state.items = items;
        },
        DO_RESET: function(state) {
            state.items = [];
        }
    },
    actions: {
        reset: function(context) {
            context.commit("DO_RESET");
        },
        setItems: function(context, items) {
            context.commit("SET_ITEMS", items);
        }
    }
}