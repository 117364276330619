import { each } from "lodash-es";

export const layout = {
    namespaced: true,
    state: {
        loader: {
            loading: false,
            message: null
        },
        transitions:  {
            globalDelay: 0,
        }
    },
    getters: {
        getLoader: (state) => { return state.loader; },
        getTransitions: (state) => { return state.transitions; }
    },
    mutations: {
        SET_LOADER: function(state, data) {
            each(data, (val, key) => {
                state.loader[key] = val;
            })
        }
    },
    actions: {
        setLoader: function(context, data) {
            context.commit("SET_LOADER", data);
        }
    }
}