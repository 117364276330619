import { each } from "lodash-es";

export const ws = {
    namespaced: true,
    state: {
        client_id: crypto.randomUUID(),
        ws: null,
        hadConnection: false,
        scanner: {
            on: false
        }
    },
    getters: {
        getWS: (state) => { return state.ws; },
        getScanner: (state) => { return state.scanner; }
    },
    mutations: {
        SET_WS: (state, ws) => {
            state.ws = ws;
        },
        SET_SCANNER: (state, data) => {
            each(data, (val, key) => {
                state.scanner[key] = val;
            })
        }
    },
    actions: {
        setScanner: function(context, data) {
            context.commit("SET_SCANNER", data);
        },
        setWS: function(context, ws) {
            context.commit("SET_WS", ws);
        },
        connect: function(context, client_id) {

            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                if(!client_id) client_id = context.state.client_id;
                var newConnection;

                try {
                    newConnection = new WebSocket(`wss://localhost:1571/connect/${client_id}`);

                    context.dispatch("setWS", newConnection);

                    newConnection.onopen = function() {
                        console.log("websocket open");
                        context.state.hadConnection = true;
                        resolve();
                    }

                    newConnection.onmessage = function(event) {
                        context.dispatch("handleWebsocketMessage", event.data);
                    }
                    
                    newConnection.onclose = function(event) {
                        console.log("webscoket closed", event);
                        context.commit("SET_SCANNER", { on: false });
                        if(context.state.hadConnection) {
                            console.log("hadConnection so trying again");
                            context.dispatch("connect");
                        }
                    }

                    newConnection.onerror = function(event) {
                        console.log("websocket error", event);
                        context.commit("SET_SCANNER", { on: false });
                        context.dispatch("settings/setup", { socket: false, device: false}, { root: true })
                        resolve();
                    }

                } catch(err) {
                    console.error(err);
                    reject(err);
                }

            })
        },
        getDeviceInfo: function(context) {
            context.dispatch("sendPayload", { command: "getDeviceInfo"});
        },
        // eslint-disable-next-line no-unused-vars
        readQR: function(context) {
            context.commit("SET_SCANNER", { on: true });
            context.dispatch("sendPayload", { command: "readQR"});
            
        },
        dispenseWater: function(context) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                var items = context.rootGetters["cart/getItems"];
                if(items) {
                    var item = items[0];
                    var temp = item.temp.val;
                    if(temp == 2) temp = 1;
                    var qty = item.size.val;
                    context.dispatch("sendPayload", { command: "dispenseWater", temp, qty });
                    resolve();
                } else {
                    reject();
                }
            })
        },
        sendPayload: function(context, { command, ...data}) {
            if(!command) command = "ping";
            var payload = { command }
            if(data) payload = { command, ...data}
            console.log(payload);
            var ws = context.getters.getWS;
            if(ws) {
                ws.send(JSON.stringify(payload));
            }
        },
        handleWebsocketMessage: function(context, message) {
            message = JSON.parse(message);
            console.log(message);
            switch(message.context) {
                case "deviceInfo":
                    context.dispatch("settings/setDevice", { ...message.device }, { root: true });
                    break;

                case "readQR":
                    context.dispatch("settings/setCode", message.code, { root: true });
                    break;

                case "readQRStopped":
                    context.commit("SET_SCANNER", { on: false });
                    break;

                case "dispenseWater":
                    console.log("water dispensing", message.dispensing);
                    break;

            }

            if(!message.context && message.ACK) {
                switch(message.ACK) {
                    case "connected":
                        setTimeout(() => { 
                            context.dispatch("settings/setup", { socket: true}, { root: true })
                            context.dispatch("getDeviceInfo"); 
                        }, 1000);
                        break;

                    case "readQR":
                        context.commit("SET_SCANNER", { on: true });
                        break;
                }
            }
        },
    }

}