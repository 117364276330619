import { createRouter, createWebHistory } from 'vue-router';
import store from "./../store/";

const routes = [
  {
    path: '/',
    name: 'setup',
    component: () => import('../views/DeviceSetup.vue')
  },
  {
    path: "/guestlist",
    name: "GuestList",
    component: () => import('../views/GuestList.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/bottles',
    name: 'bottles',
    component: () => import('../views/Bottles.vue')
  },
  {
    path: '/select',
    name: 'select',
    component: () => import('../views/Select.vue')
  },
  {
    path: '/place_bottle',
    name: 'place_bottle',
    component: () => import('../views/PlaceBottle.vue')
  },
  {
    path: '/refill_status',
    name: 'refill_status',
    component: () => import('../views/RefillStatus.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/StatsDashboard.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  if(to.path !== "/") {
    if(to.path === "/guestlist" || to.path === "/dashboard") return next();
    var ready = store.getters["settings/ready"];

    if(ready) {
      setTimeout(() => {
        next()
      }, 0)  
    } else {
      router.push({ name: "setup"});
    }
  } else {
    next();
  }
})

export default router
