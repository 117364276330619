<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
  <!-- <ThinkingOverlay /> -->
</template>

<script>
// import ThinkingOverlay from "./components/LoadingOverylay.vue";
// import LoaderElement from "./components/Loader.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "App",
    data: function () {
        return {
            base: "https://127.0.0.1:1571",
            entering: false,
            leaving: false,
            device_id: null,
            device_data: null,
            version: null
        };
    },
    computed: {
        ...mapGetters({
            ws: "ws/getWS",
            vocab: "settings/getVocab",
            loader: "layout/getLoader",
            ready: "settings/ready"
        }),
        currentRoute: function() {
          return this.$route.name;
        }
    },
    methods: {
        ...mapActions({
            connect: "ws/connect",
            loadLang: "settings/loadLang",
            setLang: "settings/setLang",
            setLoader: "layout/setLoader",
            sendPayload: "ws/sendPayload",
            settingsReset: "settings/reset",
            cartReset: "cart/reset"
        }),
        reset: function() {
            this.settingsReset();
            this.cartReset();
        },
        getCookies: function() {
          var dd = this.$cookies.get("device_data");
          if(dd) {
            this.device_data = dd;
          }
          this.device_id = this.$cookies.get("device_id");
          this.version = this.$cookies.get("version");
          console.log("cookies", {device_id: this.device_id, device_data: this.device_data, version: this.version})
        },
        setup: async function () {
            console.log("setting up...");
            this.getCookies();
            this.reset();
            //if(this.$refs) this.$refs.waveLoader.doFillup(5);
            await this.connect();
            await this.loadLang();
            //TODO: Add catches here
        }
    },
    mounted: function () {
        if(this.$route.path !== '/guestlist') this.setup();
        window.app = this;
    },
    components: { 
      //LoaderElement
      // ThinkingOverlay
    },
    watch: {
      ready: {
        immediate: true,
        // eslint-disable-next-line no-unused-vars
        handler: function(newVal, oldVal) {
          if(newVal) {
            this.setLoader({ loading: false, message: null })
            if(this.$route.path !== '/guestlist' && this.$route.path !== '/dashboard') this.$router.push({name: "home"});
          }
        }
      },
      currentRoute: function(newVal) {
        if(newVal === 'setup') this.setup();
      }
    }
}
</script>

<style lang="scss">


nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


</style>
