import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import filters from "./assets/js/filters"
import VueCookies from 'vue-cookies'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import { faAngleLeft, faInfinity, faTimesCircle, faSpinner, faCheckCircle, faGlobe } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(faAngleLeft, faInfinity, faTimesCircle, faSpinner, faCheckCircle, faGlobe)

const app = createApp(App).use(store).use(router)
app.use(VueCookies, { expires: '7d'})
app.config.globalProperties.$filters = filters;

/* add font awesome icon component */
app.component('font-awesome-icon', FontAwesomeIcon)

app.config.productionTip = false

app.mount('#app')
