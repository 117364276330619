/* eslint-disable no-unused-vars */
import Airtable from "airtable";
import { each, set, every } from "lodash-es"

var base = new Airtable({apiKey: 'key4C5RAPii1lRtaO'}).base('appMjV03f1vUPxMkB');

// const isObject = function(a) {
//     return (!!a) && (a.constructor === Object);
// };

// const isArray = function(a) {
//     return (!!a) && (a.constructor === Array);
// };

function loadBase (table, view = "Grid view") {
    return new Promise((resolve, reject) => {
        var allRecords = [];

        var options = {
            view
        }

        base(table).select(options).eachPage((records, nextPage) => {

            records.forEach(record => {

                allRecords.push({
                    recId: record.id,
                    ...record.fields
                })
            })
            
            nextPage();
        }, (err) => {
            if(err) {
                console.error(err);
                reject(err);
            } else {
                resolve(allRecords);
            }
        })
    })
}



export const settings = {
    namespaced: true,
    state: {
        vocab: {},
        lang: "en",
        kmzero_logo: require("@/assets/images/akmz-logo-am.png"),
        kmzero_logo_white: require("@/assets/images/kmzero-logo-white.png"),
        marriot_logo: require("@/assets/images/marriott-logo.png"),
        kmzero_runbott: require("@/assets/images/kmzero-runbott.png"),
        jum_blue: require("@/assets/images/logojhblue.png"),
        partner_logo: false,
        theme: {
            coin: require("@/assets/images/coin.png"),
            small_green_wave: require("@/assets/images/small-green-wave.png"),
            user_metrics: {
                money: require("@/assets/images/pig.png"),
                plastic: require("@/assets/images/plastic-bottle.png"),
            },
            profilePics: [
                // require("@/assets/images/profile_pics/01.png"),
                require("@/assets/images/profile_pics/02.png"),
                // require("@/assets/images/profile_pics/03.png"),
                require("@/assets/images/profile_pics/04.png"),
                require("@/assets/images/profile_pics/05.png"),
                require("@/assets/images/profile_pics/06.png"),
                require("@/assets/images/profile_pics/07.png"),
                require("@/assets/images/profile_pics/08.png"),
                require("@/assets/images/profile_pics/09.png"),
                require("@/assets/images/profile_pics/10.png"),
            ]
        },
        device: {
            type: null,
            id: null,
            scanner: null,
            camera: null
        },
        setup: {
            languages: null,
            socket: null,
            device: null
        },
        user: {
            uid: null
        },
        code: null,
        guest_list: [],
        dispenseTimes: {
            small: 9,
            medium: 16,
            large: 24
        }
    },
    getters: {
        getGuestList: (state) => {
            return state.guest_list;
        },
        getCode: (state) => { return state.code; },
        getDevice: (state) => {
            return state.device;
        },
        getLogos: (state) => {
            return { 
                kmzero: state.kmzero_logo, 
                marriot: state.partner_logo ? state.marriot_logo : false, 
                kmzero_white: state.kmzero_logo_white, 
                kmzero_runbott: state.kmzero_runbott,
                jum_blue: state.partner_logo ? state.jum_blue : false
            }
        },
        getVocab: (state) => { return state.vocab; },
        getCurrentVocab: (state) => {
            var v = {};
            each(state.vocab, (langs, term) => {
                v[term] = langs[state.lang]
            })
            return v;
        },
        getLang: (state) => { return state.lang; },
        getTheme: (state) => { return state.theme; },
        getSetup: (state) => { return state.setup; },
        ready: (state) => {
            return every(state.setup, s => { return s !== null; });
        },
        getUser: (state) => { return state.user; },
        getDispenseTimes: (state) => { return state.dispenseTimes; }
    },
    mutations: {
        SET_GUEST_LIST: function(state, list) {
            state.guest_list = list;
        },
        SET_USER: function(state, data) {
            each(data, (val, key) => {
                state.user[key] = val;
            })
        },
        SET_TERM: (state, { term, translations}) => {
            each(translations, (val, lang) => {
                if(!state.vocab[term]) state.vocab[term] = {};
                state.vocab[term][lang] = val;
            })
        },
        SET_LANG: (state, lang) => {
            state.lang = lang;
        },
        SET_DEVICE: function(state, data) {
            each(data, (val, key) => {
                state.device[key] = val;
            })
        },
        SET_SETUP: function(state, data) {
            each(data, (val, key) => {
                console.log(key, val);
                state.setup[key] = val;
            })
        },
        SET_CODE: function(state, code) {
            state.code = code;
        },
        DO_RESET: function(state) {
            state.user = { uid: null };
            state.code = null;
        }
    },
    actions: {
        reset: function(context) {
            context.commit("DO_RESET");
            context.commit("SET_SETUP", { languages: null, socket: null});
        },
        loadGuestList: function(context) {
            return new Promise((resolve, reject) => {
                loadBase("marriott_guest_list", "filtered_list").then(list => {
                    context.commit("SET_GUEST_LIST", list);
                    resolve();
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
            })
        },
        setUser: function(context, user) {
            context.commit("SET_USER", user);
        },
        setDevice: function(context, device) {
            context.commit("SET_DEVICE", device);
            context.commit("SET_SETUP", { device: true });
        },
        setLang: function(context, lang = "en") {
            context.commit("SET_LANG", lang);
            window.lang = lang;
        },
        loadLang: function(context) {
            return new Promise((resolve, reject) => {
                console.log("loadLang");
                context.dispatch("layout/setLoader", { loading: true, message: "loading_app_label" }, { root: true });

                loadBase("lang").then(vocab => {
                    //console.log(vocab);
                    each(vocab, (lang) => {
                        var term = lang.term;
                        var translations = {
                            en: lang.en,
                            es: lang.es || lang.en,
                            cat: lang.cat || lang.en,
                            fr: lang.fr || lang.en
                        }

                        context.commit("SET_TERM", { term, translations });
                    })

                    window.vocab = context.state.vocab;
                    //console.log(table, "loaded");
                    context.commit("SET_SETUP", { languages: true });
                    context.dispatch("layout/setLoader", { loading: false }, { root: true });
                    resolve();
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })

            })
        },
        setCode: function(context, code) {
            context.commit("SET_CODE", code)
        },
        setup: function(context, payload) {
            context.commit("SET_SETUP", payload);
        }
    }
}