import { get } from "lodash-es";
const loc = window.location.host;
const PORT = 8829;
var base;

switch(true) {
    case loc.includes("localhost"):
        base = `http://${window.location.hostname}:${PORT}`;
        break;
    
    default:
        base = "https://api.aguakmzero.com";
        break;
}

export const api = {
    namespaced: true,
    state: {
        base: base,
        h2os: {
            getUser: {
                method: "POST",
                route: "/h2os/getUser",
                type: "json"
            },
            dispense: {
                method: "POST",
                route: "/h2os/dispense",
                type: "json"
            },
            getTransactions: {
                method: "POST",
                route: "/h2os/getTransactions",
                type: "json"
            }
        },
        transactions: []
    },
    getters: {
        get: (state) => (path) => {
            var r = get(state, path, null);
            if(!r) return null;
            r.url = base + r.route;
            return r;
        },
        getTransactions: (state) => { return state.transactions; }
       
    },
    mutations: {
        SET_TRANSACTIONS: function(state, transactions) {
            state.transactions = transactions;
        }
    },
    actions: {
        loadTransactions: function(context) {
            return new Promise((resolve, reject) => {
                var url = context.getters.get("h2os.getTransactions");
            
                context.dispatch("fetch", { ...url, data: { }})
                .then(response => {
                    if(response.success && response.data && response.data.transactions) {
                        context.commit("SET_TRANSACTIONS", response.data.transactions);
                    }
                    resolve(response.data.transactions);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
            })
        },
        fetchUser: function(context, uid) {
            return new Promise((resolve, reject) => {

                var url = context.getters.get("h2os.getUser");
            
                context.dispatch("fetch", { ...url, data: { uid }})
                .then(response => {
                    if(response.success && response.data && response.data.user) {
                        context.dispatch("settings/setUser", response.data.user, {root: true});
                    }
                    resolve(response.data.user);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
            })
        },
        fetch: function(context, { url, type, method, data}) {
            return new Promise((resolve, reject) => {
                var options = {
                    method
                }

                if(type === "json") {
                    options.headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }

                    options.body = JSON.stringify(data);
                }

                fetch(url, options)
                .then( resp => resp.json()).then( d => {
                    console.log(d);
                    resolve({ ...d });
                })
                .catch( err => {
                    console.error(err);
                    reject(err);
                })
            })
        },
        dispense: function(context, { qty, temp }) {
            return new Promise((resolve, reject) => {

                var url = context.getters.get("h2os.dispense");

                var user = context.rootGetters["settings/getUser"];
                var device = context.rootGetters["settings/getDevice"];
            
                context.dispatch("fetch", { ...url, data: { qty, temp, uid: user.uid, device }})
                .then(response => {
                    
                    resolve(response.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
            })
        }
    }
}